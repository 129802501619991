import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Arrow from "../images/arrow.inline.svg";
import Layout from "../components/layout";
import FormFarmers from "../components/form-farmers";
import Seo from "../components/seo";

const FarmersPage = () => (
  <Layout>
    <Seo
      title="Farmer Network"
      description="Add extra revenue to your farming operation and enhance soil health by introducing carbon cropping practices through the Agoro Carbon Alliance."
    />
    <article className="article flow">
      <div className="hero-image-wrapper">
        <StaticImage
          className="hero-image"
          src="../images/main-farmers.jpg"
          width={637}
          height={637}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="a squatting farmer examining their crop"
        />
      </div>
      <div className="headline">
        <h1>
          Carbon is Your
          <br />
          Next Cash Crop
        </h1>
      </div>
      <div className="article-wrapper flow">
        <p>
          Agoro Carbon Alliance puts farmers at the center of the solution by
          enabling operational changes to implement carbon cropping practices
          and facilitating the process with clear and transparent guidance. This
          approach ensures the production of quality carbon credits that
          businesses around the world need in order to achieve their carbon
          emission goals. It also helps farmers maximize their carbon crop
          yield, creating high-value farm-based carbon solutions.
        </p>
        <div className="hide-for-lg flow">
          <p>
            We're looking for farmers to join our global network of producers
            committed to decarbonizing the food value chain by implementing
            production practices that provide additional revenue and long-term
            value to their farms, such as reduced tillage, cover crop usage, and
            nitrogen management.
          </p>
          <div className="sign-up">
            <p><strong>Now Enrolling U.S. Farmers</strong></p>
            <a href="https://us.agorocarbonalliance.com/farmers-advisors/" className="btn btn--primary">Learn More
              <Arrow
                width={22}
                height={20}
                aria-hidden="true"
                focusable="false"
              />
            </a>
          </div>
        </div>
        <FormFarmers />
      </div>
      <div className="floating-col flow show-at-lg">
        <div className="sign-up">
          <p><strong>Now Enrolling U.S. Farmers</strong></p>
          <a href="https://us.agorocarbonalliance.com/farmers-advisors/" className="btn btn--primary">Learn More
            <Arrow
              width={22}
              height={20}
              aria-hidden="true"
              focusable="false"
            />
          </a>
        </div>
        <p>
          We're looking for farmers to join our global network of producers
          committed to decarbonizing the food value chain by implementing
          production practices that provide additional revenue and long-term
          value to their farms, such as reduced tillage, cover crop usage, and
          nitrogen management.
        </p>
      </div>
    </article>
  </Layout>
);

export default FarmersPage;
